import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RelatoriosService {

  constructor(public http: HttpClient, public auth: AuthService) { }

  buscarFotoProduto(Parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarProdutoFoto', Parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  buscarEstoqueIndividual(Parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/buscarEstoquep', Parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  inserirFotoAleatoria(Parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/inserirFotoAleatoria', Parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  buscarLojas(parametro){
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/buscarLojasp', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  buscarParametroEmpresa(parametro){
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/buscarParametroEmpresa', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  buscarPromocaoCampanha(Parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/buscarCadastroDeCampanhas', Parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  inserirCabecalhoTransferencia(Parametro){
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/inserirCabecalhoTransferencia', Parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  inserirItensTransferencia(Parametro){
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/inserirItensTransferencia', Parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  deletarItens(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/deletarItensTransferencia', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  buscarGrupop(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/buscarGrupoCatalogo', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  buscarCatalogo(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/buscarEstoqueCatalogo', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarTelefoneCatalogo(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarTelefoneCatalogo', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }
}
