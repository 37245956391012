import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { MenuController, ModalController, Platform, ToastController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { WebServer } from '@ionic-native/web-server/ngx';
import { AvaliacaoComponent } from './componentes/avaliacao/avaliacao.component';
import { Network } from '@ionic-native/network/ngx';
import { LoadingService } from './api/loading.service';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { DatabaseService } from './api/database.service';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  appPages = [
    {
      title: 'Schedule',
      url: '/app/tabs/schedule',
      icon: 'calendar'
    },
    {
      title: 'Speakers',
      url: '/app/tabs/speakers',
      icon: 'people'
    },
    {
      title: 'Map',
      url: '/app/tabs/map',
      icon: 'map'
    },
    {
      title: 'About',
      url: '/app/tabs/about',
      icon: 'information-circle'
    }
  ];
  loggedIn = false;
  dark = false;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private webServer: WebServer,
    public modalController: ModalController,
    public network: Network,
    public loading: LoadingService,
    public networkInterface: NetworkInterface,
    public db: DatabaseService,
    private nativeAudio: NativeAudio,
    private insomnia: Insomnia
  ) {    
    this.initializeApp();
  }

  async abrirModalAvaliacao(result){
    const modal = await this.modalController.create({
      component: AvaliacaoComponent,
      componentProps: result,
      backdropDismiss: false,      
    });
    modal.onDidDismiss().then((res) => {      
    });
    return await modal.present();  
  }

  loadAudio(){
    this.nativeAudio.preloadSimple('beep', 'assets/beep.mp3').then((e) =>{
      // alert("beep success:"+e);
      this.nativeAudio.setVolumeForComplexAsset('beep', 1).then((e) =>{
        // alert("setVolumeForComplexAsset success:"+e);
      }).catch((e) => {
        // alert("setVolumeForComplexAsset error:"+e);
      });
    }).catch((e) => {      
    });
  }

  async ngOnInit() {
    this.checkLoginStatus();
    this.listenForLoginEvents();

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }
  
  async verificarConexao(){
    this.network.onDisconnect().subscribe(async () => {
      this.loading.showError('Ops', 'Você esta sem internet...');        
    });
    
    this.network.onConnect().subscribe(() => {        
      this.loading.presentToastCentro('O celular conectou outra vez à internet.');
    });
  }

  async servidorWeb(){
    this.webServer.start(8080).catch((error: any) => alert(error));      
    this.webServer.onRequest().subscribe(data => {
      var body = JSON.parse(data.body);        
      this.webServer.sendResponse(data.requestId, {
        status: 200,
        body: JSON.stringify({'status': 'deu certo'}),
        headers: {'Content-Type': 'application/json'}
      }).then((e)=> {
        if(body.status == 'teste'){
          this.loading.presentToastCentro('A comunicação com o celular foi um sucesso!');
        } else {
          this.nativeAudio.play('beep').then((e) => {
            this.abrirModalAvaliacao(body);
          }).catch((e)=>{
            this.loading.showError('Ops!',e)
          });                  
        }
      })
        .catch((error: any) => console.error(error));
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if(this.platform.is("android")){
        // console.log("android");
        this.db.createDatabase();
        this.servidorWeb();
        this.loadAudio();
      }
      this.verificarConexao();
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/login');
    });
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/login');
  }  
}
