import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { AuthService } from '../../api/auth.service';
import { CepService } from '../../api/cep.service';
import { ClientesService } from '../../api/clientes.service';
import { LoadingService } from '../../api/loading.service';

@Component({
  selector: 'app-cadastro-cliente',
  templateUrl: './cadastro-cliente.component.html',
  styleUrls: ['./cadastro-cliente.component.scss'],
})
export class CadastroClienteComponent implements OnInit {
  UF: any = [
    {"nome": "Acre", "sigla": "AC"},
    {"nome": "Alagoas", "sigla": "AL"},
    {"nome": "Amapá", "sigla": "AP"},
    {"nome": "Amazonas", "sigla": "AM"},
    {"nome": "Bahia", "sigla": "BA"},
    {"nome": "Ceará", "sigla": "CE"},
    {"nome": "Distrito Federal", "sigla": "DF"},
    {"nome": "Espírito Santo", "sigla": "ES"},
    {"nome": "Goiás", "sigla": "GO"},
    {"nome": "Maranhão", "sigla": "MA"},
    {"nome": "Mato Grosso", "sigla": "MT"},
    {"nome": "Mato Grosso do Sul", "sigla": "MS"},
    {"nome": "Minas Gerais", "sigla": "MG"},
    {"nome": "Pará", "sigla": "PA"},
    {"nome": "Paraíba", "sigla": "PB"},
    {"nome": "Paraná", "sigla": "PR"},
    {"nome": "Pernambuco", "sigla": "PE"},
    {"nome": "Piauí", "sigla": "PI"},
    {"nome": "Rio de Janeiro", "sigla": "RJ"},
    {"nome": "Rio Grande do Norte", "sigla": "RN"},
    {"nome": "Rio Grande do Sul", "sigla": "RS"},
    {"nome": "Rondônia", "sigla": "RO"},
    {"nome": "Roraima", "sigla": "RR"},
    {"nome": "Santa Catarina", "sigla": "SC"},
    {"nome": "São Paulo", "sigla": "SP"},
    {"nome": "Sergipe", "sigla": "SE"},
    {"nome": "Tocantins", "sigla": "TO"}
];

resultado: any = "";
cliente = {
cpf: '',
nome: '',
cep: '',
uf: '',
cidade: '',
endereco: '',
bairro: '',
telefone: '',
email: '',
ibge: '',
idConexao: '',
pedido: '',
ie: 'ISENTO',
regime_apuracao: '',
situacaoCliente: ''
}

desabilitarIE: boolean = true;
  constructor(public auth: AuthService,
    private cep: CepService,
    public cliProvider: ClientesService,
    public navParams: NavParams, public alertCtrl: AlertController, public loader: LoadingService,
    public modalController: ModalController) { 
      this.cliente.cpf = navParams.get('codigo');
      this.cliente.pedido = navParams.get('pedido');
    }

  ngOnInit() {}

  valuechange(newValue) {
    if(newValue.key == 'Enter' || newValue.keyCode == 13 || newValue.keyIdentifier == 'Enter'){
      this.consultarCep();
    }    
  }

  /*
  showAlert(cpfCnpj) {
    const alert = this.alertCtrl.create({
      backdropDismiss : false
    });
    alert.setTitle('Qual UF do cliente?');
    for (let i = 0; i < this.UF.length; i++) {
      const element = this.UF[i];
      alert.addInput({type: 'radio', label: element.sigla + " - " + element.nome, value: element.sigla});  
    }        
    
    alert.addButton('Cancelar');
    alert.addButton({
      text: 'OK',
      handler: data => {
        let ar = {
          "id": this.auth.Id,
          "loja": this.auth.Usuario.loja,
          "ufLoja": this.auth.Usuario.uf,
          "cnpj": this.auth.Usuario.cnpj,
          "idToken" : this.auth.Usuario.idToken,
          "token": this.auth.Usuario.token,
          "senhaCertificado": this.auth.Usuario.senhaCertificado,
          "cnpjCliente" : cpfCnpj,
          "uf": data
        }   
        
        this.cliProvider.consultarClienteWebServices(ar)
        .then((result: any) => {                          
          if(result['cStat'] == 111){
            this.desabilitarIE = true;
            let infCons = result;
            let infCad = infCons['infCad'];
            let infEndereco = infCad['ender'];
            
            this.cliente.uf = infCons['UF'];                    
            
            this.cliente.nome = infCad['xNome'];          
            this.cliente.ie = infCad['IE'];
            this.cliente.regime_apuracao = infCad['xRegApur'];
            this.cliente.situacaoCliente = infCad['cSit']; // 0 não habilitado
            
            this.cliente.cep = infEndereco['CEP'];
            this.cliente.ibge = infEndereco['cMun'];
            this.cliente.cidade = infEndereco['xMun'];
            this.cliente.bairro = infEndereco['xBairro'];
            this.cliente.endereco = infEndereco['xLgr'] +' '+ infEndereco['nro'] +' '+ infEndereco['xCpl'];
            
            if(infCad['cSit'] == 0){              
              this.loader.showError("Atenção!", "Cliente com pendências na SEFAZ!");
            }

            this.cep.buscarCep(this.cliente.cep)
            .then((result: any) => {
              if(result.erro != true){                
                this.cliente.ibge = result.ibge;                
              }
            })
            .catch((error: any) => {
              console.log('error: '+error);
            });
          } else {
            this.cliente.ie = 'ISENTO';
            this.desabilitarIE = false;
            
            this.alerta(result['xMotivo'] == null ? result['mensagem'] : result['xMotivo'])
          }
      })
      .catch((error: any) => {
        console.log('error: '+error);
      });
        console.log(data);
      }
    });    
    alert.present();
  }
  */

  consultarCep(){
    this.cliente.cep = this.cliente.cep.replace('.','').replace('-','');
    this.cep.buscarCep(this.cliente.cep)
    .then((result: any) => {
      if(result.erro != true){
        this.cliente.uf = result.uf;
        this.cliente.cidade = result.localidade;
        this.cliente.endereco = result.logradouro;
        this.cliente.bairro = result.bairro;
        this.cliente.ibge = result.ibge;
        
      } else {
        console.log(result);
      }
    })
    .catch((error: any) => {
      console.log('error: '+error);
    });
  }

  ionViewDidLoad() {
    let cpfCnpj = this.navParams.get('codigo');
    if(cpfCnpj.length == 14){
      // this.showAlert(cpfCnpj);
    }    
  }

  salvarCadastro(){    
    this.loader.showLoader("Salvando cadastro do cliente...");
    this.cliente.idConexao = this.auth.Id;
    this.cliente.telefone = this.cliente.telefone.replace('(','').replace(')','').replace(' ','').replace('-','');
    this.cliente.cep = this.cliente.cep.replace('-','').replace('.','');
    this.cliProvider.inserirClienteIndividual(this.cliente)
    .then((result: any) => {
      this.loader.hideLoader();
      this.loader.presentToast('Cliente salvo com sucesso!');
      this.resultado = result;
      this.voltarModal(this.resultado);
      // this.viewCtrl.dismiss(result);
    })
    .catch((error: any) => {
      this.loader.hideLoader();
      console.log('error: '+error);
    });
  }

  async voltarModal(data){
    // let data = { 'pedido': this.produto.pedido};
    await this.modalController.dismiss(data);
    // this.viewCtrl.dismiss();
  }

}
