import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
    // loadChildren: () => import('./pages/catalogo/catalogo.module').then( m => m.CatalogoPageModule)
    //redirectTo: '/login',
    //pathMatch: 'full'
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule)
    
  },
  {
    path: 'pre-venda',
    loadChildren: () => import('./pages/tabs-prevenda/tabs-prevenda.module').then(m => m.TabsPrevendaPageModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'contagem',
    loadChildren: () => import('./pages/tabs-contagem/tabs-contagem.module').then( m => m.TabsContagemPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'status-link',
    loadChildren: () => import('./pages/status-link/status-link.module').then( m => m.StatusLinkPageModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'vendas',
    loadChildren: () => import('./pages/vendas/vendas.module').then( m => m.VendasPageModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'estoque-consultar',
    loadChildren: () => import('./pages/estoque-consultar/estoque-consultar.module').then( m => m.EstoqueConsultarPageModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'catalogo',
    loadChildren: () => import('./pages/catalogo/catalogo.module').then( m => m.CatalogoPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'catalogo-itens',
    loadChildren: () => import('./pages/catalogo-itens/catalogo-itens.module').then( m => m.CatalogoItensPageModule)
    ,canLoad: [CheckTutorial]
  },   {
    path: 'contagem-itens',
    loadChildren: () => import('./pages/contagem-itens/contagem-itens.module').then( m => m.ContagemItensPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'contagem-detalhe',
    loadChildren: () => import('./pages/contagem-detalhe/contagem-detalhe.module').then( m => m.ContagemDetalhePageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'contagem-enviar',
    loadChildren: () => import('./pages/contagem-enviar/contagem-enviar.module').then( m => m.ContagemEnviarPageModule)
    ,canLoad: [CheckTutorial]
  },
  {
    path: 'cadastrar-servidor',
    loadChildren: () => import('./pages/cadastrar-servidor/cadastrar-servidor.module').then( m => m.CadastrarServidorPageModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'cadastrar-caixa',
    loadChildren: () => import('./pages/cadastrar-caixa/cadastrar-caixa.module').then( m => m.CadastrarCaixaPageModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'correios',
    loadChildren: () => import('./pages/correios/correios.module').then( m => m.CorreiosPageModule)
  },
  {
    path: 'cupom',
    loadChildren: () => import('./pages/cupom/cupom.module').then( m => m.CupomPageModule)
  },
  {
    path: 'frente-caixa',
    loadChildren: () => import('./pages/frente-caixa/frente-caixa.module').then( m => m.FrenteCaixaPageModule)
  },
  
  
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
