import { Component, OnInit } from '@angular/core';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from '../../api/auth.service';
import { DatabaseService } from '../../api/database.service';
import { LoadingService } from '../../api/loading.service';
import { PreVendaService } from '../../api/pre-venda.service';

@Component({
  selector: 'app-avaliacao',
  templateUrl: './avaliacao.component.html',
  styleUrls: ['./avaliacao.component.scss'],
})
export class AvaliacaoComponent implements OnInit {
  nomeCliente: any = '';
  valorVenda: any = '';
  pedidoVenda: any = '';
  dataVenda: any = '';
  nomeVendedor: any = '';
  time = 40;
  counter: { min: number, sec: number }
  constructor(private navParams: NavParams, public modalController: ModalController, private so: ScreenOrientation,
    public preVenda: PreVendaService, public auth: AuthService, public loader: LoadingService,
    private insomnia: Insomnia, public db: DatabaseService) {
    this.nomeCliente = navParams.data.nomeCliente;
    this.valorVenda = navParams.data.valorVenda;
    this.pedidoVenda = navParams.data.pedidoVenda;
    this.dataVenda = navParams.data.dataVenda;
    this.nomeVendedor = navParams.data.nomeVendedor;
    
    this.so.lock(this.so.ORIENTATIONS.LANDSCAPE);
    this.startTimer();
    this.consultarServidor();
  }

  consultarServidor(){
    this.db.consultarServidores().then((result: any) => {        
      if(result.length > 0){   
        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          if(this.auth.Id == ''){
            this.auth.Id = element.ID;
          }
        }
      } 
    }, (error) => {
      console.log("ERROR: ", error);
    });
  }

  ngOnInit() {
    
  }
  
  startTimer() {
    this.counter = { min: 0, sec: 40 } // choose whatever you want
    let intervalId = setInterval(() => {
      if (this.counter.sec - 1 == -1) {
        this.counter.min -= 1;
        this.counter.sec = 59
      } 
      else this.counter.sec -= 1
      if (this.counter.min === 0 && this.counter.sec == 0) {
        clearInterval(intervalId)
        this.orientacaoCelular();
        this.fecharTela();
        this.modalController.dismiss();        
      }
    }, 1000)
  }

  orientacaoCelular(){
    this.so.unlock();
    this.so.lock(this.so.ORIENTATIONS.PORTRAIT);
  }
  
  async avaliar(avaliar){
    this.orientacaoCelular();
    let ar = {
      "id": this.auth.Id,
      "pedido": this.pedidoVenda,
      "avaliacao": avaliar
    }
    // alert(this.auth.Id);
    this.preVenda.inserirAvaliacao(ar).then((e)=>{
      this.loader.presentToast('Avaliação enviada com sucesso! Obrigado.')
    }).catch((e) =>{
      alert(e);
    });
    // this.fecharTela();
    await this.modalController.dismiss();
  }

  fecharTela(){
    this.insomnia.allowSleepAgain()
      .then(() => console.log('success'),
      () => console.log('error')
    );
  }

}

