import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';

import { LoadingComponent } from './componentes/loading/loading.component';
import { VendasModalComponent } from './componentes/vendas-modal/vendas-modal.component';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';

import { BrMaskerModule } from 'br-mask';
import { File } from '@ionic-native/file/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
// import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { CadastroClienteComponent } from './componentes/cadastro-cliente/cadastro-cliente.component';

import { QRCodeModule } from 'angularx-qrcode';
import { PagamentoLinkComponent } from './componentes/pagamento-link/pagamento-link.component';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { CodigoBarrasComponent } from './componentes/codigo-barras/codigo-barras.component';
import { Camera } from '@ionic-native/camera/ngx';
import { ImagemAmpliadaComponent } from './componentes/imagem-ampliada/imagem-ampliada.component';
import { FinalizarCatalogoComponent } from './componentes/finalizar-catalogo/finalizar-catalogo.component';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Media } from '@ionic-native/media/ngx';
import { WebServer } from '@ionic-native/web-server/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Network } from '@ionic-native/network/ngx';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';

import { AvaliacaoComponent } from './componentes/avaliacao/avaliacao.component';

/*
import { GroupEstoquePipe } from './pipes/group-estoque.pipe';
import { ArredondamentoPipe } from './pipes/arredondamento.pipe';
import { ArrendodarPipe } from './pipes/arrendodar.pipe';
import { ConverterDataPipe } from './pipes/converter-data.pipe';
import { FormatarDinheiroPipe } from './pipes/formatar-dinheiro.pipe';
*/
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrMaskerModule,
    QRCodeModule,
    ZXingScannerModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    // ArredondamentoPipe, ArrendodarPipe, ConverterDataPipe, FormatarDinheiroPipe, GroupEstoquePipe,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  declarations: [AppComponent, LoadingComponent, VendasModalComponent, CadastroClienteComponent,
    PagamentoLinkComponent, CodigoBarrasComponent, ImagemAmpliadaComponent, FinalizarCatalogoComponent,
    AvaliacaoComponent
    // ArredondamentoPipe, ArrendodarPipe, ConverterDataPipe, FormatarDinheiroPipe, GroupEstoquePipe,
    ],
  entryComponents: [LoadingComponent, VendasModalComponent, CadastroClienteComponent, PagamentoLinkComponent,
    CodigoBarrasComponent, ImagemAmpliadaComponent, FinalizarCatalogoComponent, AvaliacaoComponent],
  providers: [InAppBrowser, SplashScreen, StatusBar, BarcodeScanner, SQLite, File, DocumentViewer, //FileTransfer,
  Camera, NativeAudio, Device, Media, WebServer, ScreenOrientation, Network, NetworkInterface, Insomnia
],
  bootstrap: [AppComponent]
})
export class AppModule {}
