import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { LoadingService } from '../../api/loading.service';

import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'app-codigo-barras',
  templateUrl: './codigo-barras.component.html',
  styleUrls: ['./codigo-barras.component.scss'],
})
export class CodigoBarrasComponent implements OnInit {
  scannerEnabled: any;
  codigoLido: any = "";
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    //BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.EAN_8,
    //BarcodeFormat.CODE_39,
    //BarcodeFormat.CODE_93,
    BarcodeFormat.QR_CODE,
  ];
  constructor(public navParams: NavParams, 
    public alertCtrl: AlertController, public loader: LoadingService,
    private modalController: ModalController) { }

  ngOnInit() {
    this.scannerEnabled = true;
  }

  public scanSuccessHandler(resultString: string) {
    this.scannerEnabled = false;
    this.codigoLido = resultString;
  }

  public enableScanner() {
    this.scannerEnabled = !this.scannerEnabled;
        
  }
  voltar(){
    this.modalController.dismiss(this.codigoLido);
  }

}
