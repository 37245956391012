import { Component, OnInit } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from '../../api/auth.service';
import { LoadingService } from '../../api/loading.service';

@Component({
  selector: 'app-pagamento-link',
  templateUrl: './pagamento-link.component.html',
  styleUrls: ['./pagamento-link.component.scss'],
})
export class PagamentoLinkComponent implements OnInit {
  qrdata: any;
  linkPagamento: '';
  value = '';
  telefone = '';
  tel = '';
  img = '';
  operadora: any = "";
  defaultHref: any = "";
  constructor(public navParams: NavParams, public auth: AuthService,
    public iab: InAppBrowser, public loader: LoadingService, public modalController: ModalController) {
    
    this.telefone = navParams.data[1];
    if(navParams.data[2] == '101'){
      this.operadora = "Cielo";
      this.value =         navParams.data[0].shortUrl;
      this.linkPagamento = navParams.data[0].shortUrl;
      // this.img = "assets/imgs/cielo.png";
    } else if(navParams.data[2] == '102'){
      this.operadora = "PicPay";
      this.value =         navParams.data[0].shortUrl;
      this.linkPagamento = navParams.data[0].shortUrl;
      // this.img = "assets/imgs/picpay.png";
    } else if(navParams.data[2] == '103'){
      this.operadora = "Boleto";
      this.value =         navParams.data[0].shortUrl;
      this.linkPagamento = navParams.data[0].shortUrl;
      // this.img = "assets/imgs/boleto.jpg";
    } else if(navParams.data[2] == '104'){
      this.operadora = "Pagar.me";
      this.value =         navParams.data[0].url;
      this.linkPagamento = navParams.data[0].url;
      // this.img = "assets/imgs/boleto.jpg";
    }
    if(this.telefone != ''){
      this.value = 'https://api.whatsapp.com/send?phone=55'+this.telefone+'&text=Link para pagamento da sua compra, você será redirecionado(a) para página da operadora. Obrigado! '+this.linkPagamento;
    }
  }

  ngOnInit() {}

  digitando(e){    
    // console.log(this.telefone);
    // console.log(this.linkPagamento);
    this.tel = this.telefone.replace('(','').replace(')','').replace('-','').replace(' ','');
    this.value = 'https://api.whatsapp.com/send?phone=55'+this.tel+'&text=Link para pagamento da sua compra, você será redirecionado(a) para página da operadora. Obrigado! '+this.linkPagamento;
  }

  enviarLink(){
    if(this.telefone.length < 10) {
      this.loader.showError("Ops", 'Esse número de telefone parece estar incompleto!');      
    } else {
      // this.socialSharing.shareViaWhatsAppToReceiver('55'+this.telefone, 'Link para pagamento da sua compra, você será redirecionado(a) para página da operadora. Obrigado!', '', this.value);
      const browser2 = this.iab.create('https://api.whatsapp.com/send?phone=55'+this.telefone+'&text=Link para pagamento da sua compra, você será redirecionado(a) para página da operadora. Obrigado! '+this.linkPagamento, '_blank');
      browser2.close();
      /*
      const browser = this.iab.create(
      'https://api.whatsapp.com/send?phone=55'+this.telefone+'&text=Link para pagamento da sua compra, você será redirecionado(a) para página da operadora. Obrigado! '+this.value);
      browser.on('loadstop').subscribe(event => {
        browser.insertCSS({ code: "body{color: red;" });
      });
      browser.close();*/
    }
  }

  voltar(){
    this.modalController.dismiss();
  }

}
