import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-imagem-ampliada',
  templateUrl: './imagem-ampliada.component.html',
  styleUrls: ['./imagem-ampliada.component.scss'],
})
export class ImagemAmpliadaComponent implements OnInit {
  public foto: any;
  constructor(private modalController: ModalController, public navParams: NavParams) { 
    this.foto = navParams.data.img;
  }

  ngOnInit() {}

  close() {
    this.modalController.dismiss();
  }

}
