import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(public loadingController: LoadingController, public alertCtrl: AlertController, public toastController: ToastController) { }

  showError(titulo, text) {    
    this.alertCtrl.create({
      // title: 'Falha',
      header: titulo,      
      message: text,
      buttons: ['OK']
    }).then((res) => {
      res.present();
    });    
  }

  async presentToastCentro(texto) {
    const toast = await this.toastController.create({
      header: 'Atenção!',
      message: texto,
      duration: 8000,
      position: 'top',
      color: 'warning'      
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  showLoader(texto) {
    this.loadingController.create({
      message: texto,
      cssClass:'custom-loader-class',
      showBackdrop: true,
      spinner: 'circular'
    }).then((res) => {
      res.present();
    });

  }

  presentToast(texto) {
    this.toastController.create({
      message: texto, //'Your settings have been saved.',
      duration: 4000,
      position: 'bottom',
      color: 'warning'      
    }).then((res) => {
      res.present();
    });
  }

  // Hide the loader if already created otherwise return error
  hideLoader() {
    this.loadingController.dismiss().then((res) => {
      console.log('Loading dismissed!', res);
    }).catch((error) => {
      console.log('error', error);
    });
  }
}
