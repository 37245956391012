import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { DocumentViewer, DocumentViewerOptions } from '@ionic-native/document-viewer/ngx';
// import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';


export class idd {
  id = '';  
}

export class User {
  codigo: string;
  descvenda: number;
  gerente: string;
  loja: string;
  nome: string;
  status: string;
  vendedor: string;
  
  constructor(codigo: string, descvenda: number, gerente: string, loja: string, nome: string, status: string, vendedor: string){
    this.codigo = 'codigo';
    this.descvenda = 1.09;
    this.loja = loja;
    this.nome = nome;
    this.status = status;
    this.vendedor = vendedor;
  }
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  catalogo: any = [];
  public celular: boolean = false;
  public modeloCelular= "";
  public currentUser: User;
  public web: boolean = false;
  public movel: boolean = false;
  public tokenFCM: any;
  public device: any;
  public cielo: boolean = false;
  public pagSeguro: boolean = false;
  public URL = 'https://winsae.com.br/tecnodata/index.php/api/Rest'; // (PRINCIPAL)
  
  public vendas =  {
    "pedi01" : {
      pedido: "",
      vendedor : "",
      bruto: 0,
      nomeVendedor: ""
    },
    "movi01" : [],
    "rece01" : [],
    "clie01" : [],
    
  }
  
  public Id: any = "1";
  public parametro: any;

  constructor(public http: HttpClient, public plat: Platform, 
    private file: File,
    public doc: DocumentViewer/*, public transfer : FileTransfer*/) { }

  public Usuario = {
    codigo: '',
    descvenda: 0,
    gerente: '',
    loja: '',
    nome: '',
    status: '',
    vendedor: '',
    longitude: 0,
    latitude: 0,
    uf: '',
    senhaCertificado: '',
    cnpj: '',
    idToken: '',
    token: ''
  }

  
  download(chave) {
    /*const opt: DocumentViewerOptions = {
      title: "Nota Fiscal"
    }
    let path = null;
    if(this.plat.is('ios')){
      path = this.file.documentsDirectory;
    } else {
      path = this.file.dataDirectory;
    }

    const fileTransfer = this.transfer.create();
    const url = this.URL + '/downloadNFe?chave='+chave;
    fileTransfer.download(url, path + chave+'.pdf').then((entry) => {      
      this.doc.viewDocument(entry.toURL(), 'application/pdf', opt);
    }, (error) => {
      // handle error
    });
    */
  }
  
  

  buscarConferenciaPedido(Parametro) {    
    this.parametro = {
      "id": this.Id,
      "pedido": Parametro
    }
    return new Promise((resolve, reject) => {
      this.http.post(this.URL + '/buscarPedido', this.parametro)
        .subscribe((result: any) => {
          if(result._body != ""){            
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  atualizarEAN(codprod, numero, codbarra) {    
    this.parametro = {
      "id": this.Id,
      "codprod": codprod,
      "numero": numero,
      "codbarra": codbarra
    }
    return new Promise((resolve, reject) => {
      this.http.post(this.URL + '/atualizarEAN13', this.parametro)
        .subscribe((result: any) => {
          if(result._body != ""){            
            resolve(result);
          }
        },
        (error) => {
          //reject(error);
          resolve(error);
        });
    });
  }
  
  login(credentials) {
    this.Id = credentials['id'];
    return new Promise((resolve, reject) => {
      this.http.post(this.URL + '/loginp', credentials)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {          
          reject(error);
        });
    });
  }

  insertCelular(credentials) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.URL + '/insertCelular', credentials)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {          
          reject(error);
        });
    });
  }

  loginCredencial(credentials) {
    return new Promise((resolve, reject) => {
      this.http.post(this.URL + '/credenciarDescontop', credentials)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  public register(credentials) {
    if (credentials.email === null || credentials.password === null) {
      return Observable.throw("Please insert credentials");
    } else {
      // At this point store the credentials to your backend!
      return Observable.create(observer => {
        observer.next(true);
        observer.complete();
      });
    }
  }

  public getUserInfo() : User {
    return this.currentUser;
  }

  public logout() {
    return Observable.create(observer => {
      this.currentUser = null;
      observer.next(true);
      observer.complete();
    });
  }

  consultarServidor(cnpj){
    return new Promise((resolve, reject) => {
      this.http.post(this.URL + '/consultarServidor', cnpj)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        });
    });
  }
}
