import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AuthService } from '../api/auth.service';
@Injectable({
  providedIn: 'root'
})
export class CheckTutorial implements CanLoad {
  constructor(private storage: Storage, private router: Router, private auth: AuthService) {}

  /*canLoad() {
    return this.storage.get('ion_did_tutorial').then(res => {
      console.log(res);
      if (res) {
        this.router.navigate(['/login']);
        return false;
      } else {
        return true;
      }
    });
  }*/

  canLoad() {
    return this.storage.get('ID').then(val => {
      if(val != null){        
        // console.log(val);
        const element = val;
        this.auth.Id = element.ID;
        this.auth.Usuario.loja = element.LOJA;
        this.auth.Usuario.nome = element.NOME;
        this.auth.Usuario.codigo = element.CODIGO;
        this.auth.Usuario.descvenda = element.DESCVENDA;
        this.auth.Usuario.uf = element.UF;
      }
      
      return true
      /*if (res) {
        this.router.navigate(['/app', 'tabs', 'schedule']);
        return false;
      } else {
        return true;
      }*/
    });
  }  
}
