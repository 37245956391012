import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PreVendaService {

  constructor(public http: HttpClient, public auth: AuthService) { }

  inserirAvaliacao(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/insertAvaliacaoVenda', parametro)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }


  deletarItens(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/deletarItensPedido', parametro)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  inserirItens(itens) {
    if(itens[1][0]['pedido'] == undefined){      
      itens[1][0]['pedido'] = "";
    }
    if(itens[1][0]['pedido'] == 'undefined'){      
      itens[1][0]['pedido'] = "";
    }    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/inserirItensp', itens)
        .subscribe((result: any) => {                    
          resolve(result);
        },
        (error) => {

          reject(console.log(error));
        });
    });
  }

  consultarPedidos(pedido) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarMovimentacaoItensPedido', pedido)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarCondicao(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarCondicaoPagto', parametro)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarVendedores(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarVendedoresPorLoja', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarTipoDeDocumento(parametro) {
    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultaTipoDeDocumento', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }
  

  apagarPedido(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/deletarPedido', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  atualizarPedido(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/atualizarVendaFinalizarPedido', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  inserirLocalizacaoLojaVendedor(parametro){
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/inserirLocalizacaoLojaVendedor', parametro)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  motivoDesistenciaVenda(parametro){
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/motivoDesistenciaVenda', parametro)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  insertPontoInteligente(parametro){
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/insertPontoInteligente', parametro)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarPedidoPreVenda(){
    let arr = {
      "id": this.auth.Id,
      "loja": this.auth.Usuario.loja
    }
  
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarPedidoPreVenda', arr)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }


  consultarPedidoNumero(pedido){
    let arr = {
      "id": this.auth.Id,
      "pedido": pedido
    }
  
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarPedidoPorNumero', arr)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarPedidoNumeroItens(pedido){
    let arr = {
      "id": this.auth.Id,
      "pedido": pedido
    }
  
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarPedidoPorNumeroItens', arr)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  atualizarPedidoNFCe(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/atualizarRece01NFCe', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  emitirCupomFiscal(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/emitirNFe', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  pesquisarAberturaDeCaixa(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/pesquisarFundoDeCaixa', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  abrirFundoDeCaixa(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/abrirFundoDeCaixa', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  buscarFecharCaixa(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/buscarFechamentoDeCaixa', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  salvarFecharCaixa(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/salvarFechamentoDeCaixa', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }
  
  buscarVendasCancelamento(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/desempenhoVendasVendedores', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  efetuarCancelamento(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/cancelarNFe', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  efetuarCancelamentoVenda(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/cancelarVenda', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarPedidosDevolucao(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/buscarPedidoDevolucao', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }
  
  consultarItensPedidosDevolucao(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/buscarItensPedidoDevolucao', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  inserirItensPedidosDevolucao(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/inserirItensDevolucao', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  enviarLinkPagarme(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/criarLinkPagarme', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  enviarLinkCielo(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/criarLinkCielo', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  enviarLinkPicPay(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/criarLinkPicPay', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  enviarBoleto(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/gerarBoletoApiPagHiper', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  statusPagamentoPorLink(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarStatusLinkPagamento', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  consutarCorreios(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/buscarVendasCorreios', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  inserirCorreios(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/atualizarPedidoWP', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  statusLinkPicPay(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/statusPicPay', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  statusBoletoApiPagHiper(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/statusBoletoApiPagHiper', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarStatusLinkCielo(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarStatusLinkCielo', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarStatusLinkPagarme(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarStatusLinkPagarme', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarToken(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/validarDadosLio', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  criarVendaLio(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/criarVendaLio', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarVendaLio(parametro) {    
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarVendaLio', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);            
          }
        },
        (error) => {
          reject(error);
        });
    });
  }
}
