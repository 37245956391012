import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AuthService } from '../../api/auth.service';
import { LoadingService } from '../../api/loading.service';
import { PreVendaService } from '../../api/pre-venda.service';

import { UserData } from '../../providers/user-data';


@Component({
  selector: 'app-vendas-modal',
  templateUrl: './vendas-modal.component.html',
  styleUrls: ['./vendas-modal.component.scss'],
})
export class VendasModalComponent implements OnInit {
  converterData: any = new Date().toISOString();
  defaultHref: any = "pre-venda/tabs-prevenda";
  idConexao = {
    id : '',
    operador: ''
  }
  json: any[] = [];
  public detalhe: any[] = [];
  totalItens: any = 0;
  produto = {
    codprod: '',
    numero: '',
    descricao: '',
    referencia: '',
    venda: 0,
    quantidade: 1,
    descontoUnitario: 0,
    custo: 0,
    preco_bruto: 0,
    pedido: '',
    loja: '',
    comissao: 0,
    promocao: 0,
    dta_ped: this.converterData.substr(0,10),
    promoid: null,
    foto: null,
    iniprom: '',
    fimprom: '',
    descontoTipo: '',
    operador: '',
    estoque: {
      LOJA1: '',
      LOJA2: '',
      LOJA3: '',
      LOJA4: '',
      LOJA5: '',
      LOJA6: '',
      LOJA7: '',
      LOJA8: '',
      LOJA9: '',
      LOJA10: ''
    }
  }
  valorTotal: any = 0;
  descontoMaximoProduto: any;
  //promocao: any = 0;
  descontoMaximoProdutoValor: any;
  radioVendedorOpen: boolean;
  radioVendedorResult: string;
  public isToggled: boolean;
  public tipoDesconto: string;
  registrarCredenciaisDesconto = { id: '', codigoUsuario: '', senha: '' , percentualDesc: 0};
  
  descontoProgressivo: boolean = false;
  constructor(public preVendaProvider: PreVendaService, private storage: Storage,public auth: AuthService,
    private navParams: NavParams, public modalController: ModalController, public alertCtrl: AlertController,
    public load: LoadingService, public userData: UserData) {
    this.produto.codprod = navParams.data[0]['CODPROD'];
    this.produto.numero = navParams.data[0]['NUMERO'];
    this.produto.descricao = navParams.data[0]['DESCRICAO'];
    this.produto.referencia = navParams.data[0]['REFERENCIA'];
    this.produto.venda = navParams.data[0]['VENDA'];
    this.descontoMaximoProduto = navParams.data[0]['DESCONTO'];
    this.descontoMaximoProdutoValor = ((+this.produto.venda) * ((+this.descontoMaximoProduto)/100));
    this.produto.preco_bruto = navParams.data[0]['VENDA'];
    this.produto.custo = navParams.data[0]['CUSTO'];
    this.produto.comissao = navParams.data[0]['COMISSAO'];
    this.produto.promocao = navParams.data[0]['PROMOCAO'];
    this.isToggled = false;
    this.tipoDesconto = 'valor';
    this.produto.loja = this.auth.Usuario.loja;
    this.produto.pedido = navParams.data[1]['pedidoOrigem'];
    this.produto.promoid = navParams.data[0]['PROMOID'];
    this.totalItens = navParams.data[0]['PROMOCAO'] > 0 ? navParams.data[0]['PROMOCAO'] : navParams.data[0]['VENDA'];
    this.produto.foto = navParams.data[0]['FOTO'];
    this.produto.iniprom = navParams.data[0]['INIPROM'];
    this.produto.fimprom = navParams.data[0]['FIMPROM'];
    this.produto.estoque = navParams.data[0];    
    this.consultarValorTotal();
    this.storage.get('CAMPANHA').then((val) => {
      if(val != null){
        for (let i = 0; i < val.length; i++) {
          const element = val[i];
          if(+this.produto.quantidade >= element.QTDADE){
            this.totalItens = 0;
            this.descontoProgressivo = true;
            this.tipoDesconto = 'percentual';
            this.isToggled = true;
            this.produto.descontoUnitario = element.DESCPERC;
            this.totalItens = (this.produto.preco_bruto * (((100-element.DESCPERC)/100) ));
            this.produto.iniprom = element.INIPROM;
            this.produto.fimprom = element.FIMPROM;
          }          
        }
      }
    });
   }

  ngOnInit() {
    this.defaultHref = "pre-venda/tabs-prevenda";
    
  }

  consultarValorTotal(){
    this.userData.getValorPedido().then((valorTotal) => {
      console.log(valorTotal);
      this.valorTotal = valorTotal;
    });
  }
  
  public notify() {
    this.produto.descontoUnitario = 0;
    if(this.isToggled == true){
      this.tipoDesconto = 'percentual';
    } else {
      this.tipoDesconto = 'valor';
    }
  }

  async closeModal(){
    await this.modalController.dismiss();
  }

  onKeyQt2(event: any) {
    var valorProd:number = this.produto.promocao > 0 ? this.produto.promocao : this.produto.preco_bruto;
    var qtdadeVal:number = parseFloat(event.srcElement.value);
    
    this.totalItens = qtdadeVal * valorProd; //this.produto.promocao > 0 ? this.produto.promocao : this.produto.preco_bruto;    
    this.storage.get('CAMPANHA').then((val) => {
      if(val != null){        
        if(this.produto.promoid != null){
          for (let i = 0; i < val.length; i++) {
            const element = val[i];
            this.produto.descontoUnitario = 0;
            this.produto.descontoTipo = '';
            if(+qtdadeVal >= element.QTDADE){
              this.totalItens = 0;
              this.descontoProgressivo = true;
              this.tipoDesconto = 'percentual';
              this.isToggled = true;
              this.produto.descontoUnitario = element.DESCPERC;
              this.totalItens = (this.produto.preco_bruto * (((100-element.DESCPERC)/100) )) * qtdadeVal;
              this.produto.descontoTipo = 'C;'+element.ID+';'+qtdadeVal;
              this.produto.iniprom = element.INIPROM;
              this.produto.fimprom = element.FIMPROM;
            }
          }
        } else {
          if(this.tipoDesconto != 'percentual'){
            this.totalItens = (valorProd * (qtdadeVal)) - ( (qtdadeVal) * parseFloat(this.produto.descontoUnitario.toString().replace(',','.')));                        
          } else {
            this.totalItens = (valorProd * (qtdadeVal)) - ( ((qtdadeVal)/100) * (parseFloat(this.produto.descontoUnitario.toString().replace(",",".")) * this.produto.venda));
          }
        }
      } else {
        if(this.tipoDesconto != 'percentual'){
          this.totalItens = (valorProd * (qtdadeVal)) - ( (qtdadeVal) * parseFloat(this.produto.descontoUnitario.toString().replace(',','.')));
        } else {
          this.totalItens = (valorProd * (qtdadeVal)) - ( ((qtdadeVal)/100) * (parseFloat(this.produto.descontoUnitario.toString().replace(",",".")) * this.produto.venda));
        }
      }
    });
  }

  onKeyQt(event: any) { 
    // var valorProd:number = this.produto.venda;
    var descontoUnit:number = parseFloat(event.srcElement.value.toString().replace(',','.'));
    if(this.tipoDesconto != 'percentual'){
      this.totalItens = (this.produto.venda * parseFloat(this.produto.quantidade.toString().replace(",","."))) - descontoUnit;
    } else {
      this.totalItens = (this.produto.venda * parseFloat(this.produto.quantidade.toString().replace(",","."))) - ( ((descontoUnit)/100) * (parseFloat(this.produto.quantidade.toString().replace(",",".")) * this.produto.venda));
    }    
  }

  async alertaDesconto() {
    const prompt = await this.alertCtrl.create({
      header: 'Atenção!',
      message: 'Permissão de desconto',            
      backdropDismiss: false,
      inputs: [
        {
          name: 'idMaster',
          placeholder: 'Código'
        },
        {
          name: 'senhaMaster',
          placeholder: 'Senha',
          type: 'password'
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          handler: data => {
            
          }
        },
        {
          text: 'Confirmar',
          handler: data => {
            this.validarDescontoMaster(data);
          }
        }
      ]
    });
    prompt.present();
  }

  public venderItem(){
    var descUnit: any = this.produto.descontoUnitario;
    if(!(typeof this.produto.descontoUnitario === 'number')){
      this.produto.descontoUnitario = parseFloat(descUnit.toString().replace(",","."));      
    }
    if(this.tipoDesconto == 'valor'){
      if(+this.produto.descontoUnitario > 0 && +this.produto.promocao > 0){
        this.load.showError("Ops", "Não se aplica desconto em produtos que estão em promoção!")
      } else {
        if(+this.produto.descontoUnitario > +this.descontoMaximoProdutoValor && this.descontoProgressivo == false){
          this.alertaDesconto();
        } else {
          if(+this.produto.promocao > 0){
            this.produto.venda = this.decimalAdjust('round', +this.produto.promocao, -2);
            this.inserirItemBD();
          } else {
            var desc = parseFloat(this.produto.descontoUnitario.toString().replace(",","."));            
            this.produto.venda = this.decimalAdjust('round', (this.produto.preco_bruto - desc), -2);            
            this.inserirItemBD();
          }
          
        }
      }
    } else {
      if(+this.produto.descontoUnitario > 0 && +this.produto.promocao > 0){
        this.load.showError("Ops", "Não se aplica desconto em produtos que estão em promoção!")
      } else {
        if(+this.produto.descontoUnitario > +this.descontoMaximoProduto && this.descontoProgressivo == false){
          this.alertaDesconto();
        } else {
          if(+this.produto.promocao > 0){
            this.produto.venda = this.decimalAdjust('round', +this.produto.promocao, -2);
            this.inserirItemBD();
          } else {
            this.produto.venda = this.decimalAdjust('round', this.produto.preco_bruto - ((+this.produto.descontoUnitario/100)*this.produto.preco_bruto), -2);
            this.inserirItemBD();
          }
        }
      }
      
    }
  }

  decimalAdjust(type, value, exp) {
		// If the exp is undefined or zero...
		if (typeof exp === 'undefined' || +exp === 0) {
			return Math[type](value);
		}
		value = +value;
		exp = +exp;
		// If the value is not a number or the exp is not an integer...
		if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
			return NaN;
		}
		// Shift
		value = value.toString().split('e');
		value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
		// Shift back
		value = value.toString().split('e');
		return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
	}

  public validarDescontoMaster(dados){
    this.registrarCredenciaisDesconto.id = this.auth.Id;
    this.registrarCredenciaisDesconto.codigoUsuario = dados['idMaster'];
    this.registrarCredenciaisDesconto.senha = dados['senhaMaster'];
    if(this.tipoDesconto == 'percentual'){
      this.registrarCredenciaisDesconto.percentualDesc = +this.produto.descontoUnitario;
    } else {
      this.registrarCredenciaisDesconto.percentualDesc = this.decimalAdjust('round', 100*( +this.produto.descontoUnitario / +this.produto.preco_bruto ), -2);   
    }
    
    this.auth.loginCredencial(this.registrarCredenciaisDesconto)
      .then((result: any) => {
        if(result == true){
          this.produto.venda = this.decimalAdjust('round', this.produto.preco_bruto-((+this.registrarCredenciaisDesconto.percentualDesc/100)*this.produto.preco_bruto), -2);
          this.inserirItemBD();
        } else {
          this.load.showError("Ops", "Sem permissão para desconto!");
        }
      })
      .catch((error: any) => {
        this.load.presentToast(error);
      });
  }

  
  async inserirItemBD(){    
    this.load.showLoader("Aguarde, inserindo o item...");
    if(''+this.produto.quantidade == "" || this.produto.quantidade < 0){
      this.load.showError("Ops", "A quantidade tem que ser maior que zero!")      
    } else {
      this.detalhe = [];
      this.json = [];
      this.detalhe.push(this.produto);
      this.idConexao.id = this.auth.Id;
      this.idConexao.operador = this.auth.Usuario.codigo;
      this.json.push(this.idConexao);
      this.json.push(this.detalhe);    
      this.preVendaProvider.inserirItens(this.json)
      .then((result: any) => {
        this.load.hideLoader();
        if(result != ""){          
          let data = { 'pedido': result, 'produto': this.produto, 'valorProduto': (+this.produto.venda * +this.produto.quantidade)};          
          this.load.presentToast('Item inserido com sucesso!');  
          this.voltarModal(data);          
        }
      })
      .catch((error: any) => {
        console.log(error);
        this.load.hideLoader();
        this.load.presentToast(error);                
      });
    }
    
  }

  async voltarModal(data){
    // let data = { 'pedido': this.produto.pedido};
    await this.modalController.dismiss(data);
    // this.viewCtrl.dismiss();
  }


  finalizarVendas(){
    
  }
}
