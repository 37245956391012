import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CepService {

  constructor(public http: HttpClient) { }

  buscarCep(cep) {
    return new Promise((resolve, reject) => {
      this.http.get("https://viacep.com.br/ws/"+cep+"/json/")
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }
}
