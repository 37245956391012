import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Device } from '@ionic-native/device/ngx';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(public http: HttpClient, private sqlite: SQLite, private device: Device) { }

  public getDB(){
    if(this.device.platform =='iOS') {
      //this.alert();
      return this.sqlite.create({
        name: 'winsae_ios.db',
        iosDatabaseLocation: 'Documents'
      });
    } else {
      //this.alert();
      return this.sqlite.create({
        name: 'winsae_android.db',
        location: 'default'
      });
    }
    
    
  }

  /**
   * Cria a estrutura inicial do banco de dados
   */
  public createDatabase() {
    return this.getDB()
      .then((db: SQLiteObject) => {
        this.createTables(db);
      })
      .catch(e => console.log(e));
  }

  /**
   * Criando as tabelas no banco de dados
   * @param db
   */
  private createTables(db: SQLiteObject) {
    // Criando as tabelas
    db.sqlBatch([
      ['CREATE TABLE IF NOT EXISTS balanco (pedido INTEGER PRIMARY KEY AUTOINCREMENT NOT NULL, data TEXT, loja TEXT, uuid TEXT, platform TEXT, modelo TEXT, data_envio TEXT)'],
      ['CREATE TABLE IF NOT EXISTS balanco_itens (ID INTEGER PRIMARY KEY AUTOINCREMENT NOT NULL, pedido TEXT NOT NULL, codigo TEXT, quantidade TEXT)'],
      ['CREATE TABLE IF NOT EXISTS maquininha (ID INTEGER PRIMARY KEY AUTOINCREMENT NOT NULL, ADQUIRENTE TEXT NOT NULL, LOJA TEXT, ATIVO TEXT)'],
      ['CREATE TABLE IF NOT EXISTS servidor (ID INTEGER PRIMARY KEY, TOKEN TEXT, USUARIO TEXT, SENHA TEXT, EMPRESA TEXT)'],
      ['CREATE TABLE IF NOT EXISTS caixa (ID INTEGER PRIMARY KEY, NUMEROCAIXA TEXT)']
    ])
      .then(() => console.log('Tabelas criadas'))
      .catch(e => console.error('Erro ao criar as tabelas', e));
  }

  public consultarCaixa(){
    return new Promise((resolve, reject) => {
      this.getDB().then((db: SQLiteObject) => {                
        var sql = "";        
        sql = ' select ID, NUMEROCAIXA from CAIXA ';                
        db.executeSql(sql, []).then((data: any) => {   
            let maquininhas = [];
            if(data.rows.length > 0) {
                for(let i = 0; i < data.rows.length; i++) {
                  maquininhas.push(data.rows.item(i));
                }
            }              
            resolve(maquininhas);
        }).catch((e) => console.log("consultarMaquininhas_1_"+e.message));
      })
      .catch((e) => console.log("consultarMaquininhas_2_"+e.message));        
    });
  }

  public insertCaixa(id, caixa) {
    this.removeCaixa();
    return this.getDB()
      .then((db: SQLiteObject) => {
        let sql = ' insert into CAIXA (ID, NUMEROCAIXA) values (?, ?) ';
        let data = [id, caixa];
        return db.executeSql(sql, data).then((e) => {
          console.log(e);
        }).catch((e) => e) 
        })
      .catch((e) => console.error(e));      
  }

  public removeCaixa() {
    return this.getDB()
      .then((db: SQLiteObject) => {
        let sql = 'delete from CAIXA';
        let data = [];
        return db.executeSql(sql, data).then((e) => {
          console.log(e);
        }).catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  }

  public insertMaquininha(adquirente, loja, ativo) {
    this.removeMaquininha(adquirente, loja);
    return this.getDB()
      .then((db: SQLiteObject) => {
        let sql = ' insert into MAQUININHA (ADQUIRENTE, LOJA, ATIVO) values (?, ?, ?) ';
        let data = [adquirente, loja, ativo];
        return db.executeSql(sql, data).then((e) => {
          console.log(e);
        }).catch((e) => e) 
        })
      .catch((e) => console.error(e));      
  }

  public insertServidor(id, token, usuario, senha, empresa) {
    this.remove(id);
    return this.getDB()
      .then((db: SQLiteObject) => {
        let sql = ' insert into SERVIDOR (ID, TOKEN, USUARIO, SENHA, EMPRESA) values (?, ?, ?, ?, ?) ';
        let data = [id, token, usuario, senha, empresa];
        return db.executeSql(sql, data).then((e) => {
          console.log(e);
          }).catch((e) => e)
        })
      .catch((e) => console.error(e));      
  }

  public updateServidor(id, token, usuario, senha) {
    return this.getDB()
      .then((db: SQLiteObject) => {
        let sql = ' update SERVIDOR set TOKEN = ?, USUARIO = ?, SENHA = ? where ID = ? ';
        let data = [token, usuario, senha, id];
        return db.executeSql(sql, data).then((e) => {
          console.log(e);
        }).catch((e) => e)
        })
      .catch((e) => console.error(e));      
  }

  public remove(id) {
    return this.getDB()
      .then((db: SQLiteObject) => {
        let sql = 'delete from SERVIDOR where ID = ?';
        let data = [id];
        return db.executeSql(sql, data).then((e) => {
          console.log(e);
        }).catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  }

  public removeMaquininha(adquirente, loja) {
    return this.getDB()
      .then((db: SQLiteObject) => {
        let sql = 'delete from MAQUININHA where ADQUIRENTE = ? and LOJA = ?';
        let data = [adquirente, loja];
        return db.executeSql(sql, data).then((e) => {
          console.log(e);
        }).catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  }

  public consultarMaquininha(){
    return new Promise((resolve, reject) => {
      this.getDB().then((db: SQLiteObject) => {                
        var sql = "";        
        sql = ' select ID, ADQUIRENTE, LOJA, ATIVO from MAQUININHA ';                
        db.executeSql(sql, []).then((data: any) => {   
            let maquininhas = [];
            if(data.rows.length > 0) {
                for(let i = 0; i < data.rows.length; i++) {
                  maquininhas.push(data.rows.item(i));
                }
            }              
            resolve(maquininhas);
        }).catch((e) => console.log("consultarMaquininhas_1_"+e.message));
      })
      .catch((e) => console.log("consultarMaquininhas_2_"+e.message));        
    });
  }

  public consultarServidores(){
    return new Promise((resolve, reject) => {
      this.getDB().then((db: SQLiteObject) => {        
        // sql = ' select idcliente as CODCLIE, nomecliente as NOME, cnpjcliente as CGC, cpfcliente AS CPF, cepcliente AS CEP, ufcliente AS UF, cidadecliente AS CIDA, datainclusao, whatsapp AS TELE, endereco AS ENDE, bairro AS BAIRRO, bloqueado as BLOQUEADO, COD_UF, COD_CIDA from cliente where nomecliente like "'+nome+'%" or cnpjcliente = "'+nome+'" or cpfcliente = "'+nome+'" group by idcliente, nomecliente, cnpjcliente, cpfcliente, cepcliente, ufcliente, cidadecliente, datainclusao, whatsapp, endereco, bairro, bloqueado, COD_UF, COD_CIDA order by nomecliente ';
        var sql = "";        
        sql = ' select ID, TOKEN, USUARIO, SENHA, EMPRESA from servidor ';                
        db.executeSql(sql, []).then((data: any) => {   
            let servidores = [];
            if(data.rows.length > 0) {
                for(let i = 0; i < data.rows.length; i++) {
                  servidores.push(data.rows.item(i));
                }
            }              
            resolve(servidores);
        }).catch((e) => console.log("consultarServidores_1_"+e.message));
      })
      .catch((e) => console.log("consultarServidores_2_"+e.message));        
    });
  }

  public consultarServidorID(id){
    return new Promise((resolve, reject) => {
      this.getDB().then((db: SQLiteObject) => {        
        // sql = ' select idcliente as CODCLIE, nomecliente as NOME, cnpjcliente as CGC, cpfcliente AS CPF, cepcliente AS CEP, ufcliente AS UF, cidadecliente AS CIDA, datainclusao, whatsapp AS TELE, endereco AS ENDE, bairro AS BAIRRO, bloqueado as BLOQUEADO, COD_UF, COD_CIDA from cliente where nomecliente like "'+nome+'%" or cnpjcliente = "'+nome+'" or cpfcliente = "'+nome+'" group by idcliente, nomecliente, cnpjcliente, cpfcliente, cepcliente, ufcliente, cidadecliente, datainclusao, whatsapp, endereco, bairro, bloqueado, COD_UF, COD_CIDA order by nomecliente ';
        var sql = "";        
        sql = ' select ID, TOKEN, USUARIO, SENHA, EMPRESA from SERVIDOR where ID = ? ';
        db.executeSql(sql, [id]).then((data: any) => {   
            let servidores = [];
            if(data.rows.length > 0) {
                for(let i = 0; i < data.rows.length; i++) {
                  servidores.push(data.rows.item(i));
                }
            }              
            resolve(servidores);
        }).catch((e) => console.log("1_"+e.message));
      })
      .catch((e) => console.log("2_"+e.message));        
    });
  }
}
