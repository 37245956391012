import { Component, OnInit } from '@angular/core';
import { ActionSheetController, ModalController, NavParams } from '@ionic/angular';
import { AuthService } from '../../api/auth.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { RelatoriosService } from '../../api/relatorios.service';
import { LoadingService } from '../../api/loading.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-finalizar-catalogo',
  templateUrl: './finalizar-catalogo.component.html',
  styleUrls: ['./finalizar-catalogo.component.scss'],
})
export class FinalizarCatalogoComponent implements OnInit {
  itens: any
  telefone: any = "";
  id: any = "";
  constructor(public navParams: NavParams, public modalController: ModalController,
    public actionSheetController: ActionSheetController, public auth: AuthService,
    public iab: InAppBrowser, public rel_estoque: RelatoriosService, public load: LoadingService,
    private route: ActivatedRoute, private router: Router) {     
    this.itens = navParams.data.data;
    this.route.queryParams.subscribe(params => {
      if (params && params.empresa) {        
        let a = JSON.parse(params.empresa);
        this.id = a.id;        
        this.buscarTelefone();
      }
    });
  }

  ngOnInit() {}

  close() {
    this.modalController.dismiss();
  }

  editar(e){
    this.presentActionSheet(e)
  }

  async presentActionSheet(e) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Exclusão',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Desistir',
        role: 'destructive',
        icon: 'trash',
        handler: () => {
          for (let i = 0; i < this.auth.catalogo.length; i++) {
            const element = this.auth.catalogo[i];
            if(element.codigo == e.codigo && element.tamanho == e.tamanho){
              this.auth.catalogo.splice(i, 1);
            }
          }
        }
      }, {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  finalizar(){
    let texto = "Gostei desses produtos, \n\n";
    for (let i = 0; i < this.auth.catalogo.length; i++) {
      const element = this.auth.catalogo[i];
      texto += 'código: '+element.codigo + ' tamanho: '+ element.tamanho + ' ' + element.grupo + ' ' + element.marca + ' ' + element.cor + '; \n';
    }
    const browser2 = this.iab.create('https://api.whatsapp.com/send?phone=55'+this.telefone+'&text='+texto, '_blank');
    browser2.close();
  }

  buscarTelefone(){
    this.rel_estoque.consultarTelefoneCatalogo({"id": this.id}).then((result: any) => {            
      this.telefone = result.TELEFONE;
    }).catch((error: any) => {
      this.load.hideLoader();
    });
  }

  


}
